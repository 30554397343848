import fairImg from '../../../Assets/Images/fair_img.png'
import { Button } from "antd"
import { useHistory } from 'react-router-dom'
import './FairSection.sass'

const FairSection = (props) => {
    const history = useHistory()
    return (<>
        <div className="fairSec">
            <div className="fairWrap">
                <h2>Transparency and <strong>fairness guaranteed</strong></h2>
                <div className="fairContent">
                    <div className="left">
                        <p>
                            The excitement peaks as we approach the draw. 
                            A pivotal moment that will take place in Switzerland, live. 
                            To ensure the integrity of this process, we have requested 
                            the presence of a reputable Swiss law firm. They will be 
                            there to oversee the course of the draw, thus guaranteeing 
                            the transparency and fairness that we commit to uphold. 
                            Join us live for the grand event where the winner of the 
                            apartment will be revealed!
                        </p>
                        <Button className='learn-more' onClick={() => history.push('/conditions')}>
                            LEARN MORE
                        </Button>
                    </div>
                    <div className="right">
                        <img src={fairImg} className="mapImg" alt="" />
                    </div>
                </div>
                <span className="insideCircle">&nbsp;</span>
            </div>
            <span className="pinkCircle">&nbsp;</span>
            <span className="grayCircle">&nbsp;</span>
        </div>
    </>)
}

export default FairSection;
