import { useState, useEffect } from 'react'
import styles from './styles.module.sass'
import { api_url } from "../../Constants"

const Winner = () => {
    const keyHash = process.env.REACT_APP_APP_SECRET_KEY;
    // const [tCount, setTCount] = useState(1);
    const [isSaving, setIsSaving] = useState(false);
    const [ticketData, setTicketData] = useState([]);
    const [winner, setWinner] = useState('');

    useEffect(() => {
        const fetchTicketData = async () => {
            const response = await fetch(api_url + "/get-tickets", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({hash: keyHash})
            });
            const data = await response.json();
            if( data ) {
                setTicketData(data)
            } 
        };
        fetchTicketData();
    }, [keyHash]);

    useEffect(() => {
        const fetchWinnerData = async () => {
            const response = await fetch(api_url + "/get-winner", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({hash: keyHash})
            });
            const data = await response.json();
            if( data ) {
                setWinner(data[0])
            } 
        };
        fetchWinnerData();
    }, [keyHash]);

    const generateRandom = (min, max) => {
        return Math.floor(Math.random() * (max - min + 1)) + min;
    }

    const generateWinner = () => {
        if( isSaving ) return;
        setIsSaving(true);
        let time = 0;
        let idx = 0;
        const interval = setInterval(() => {
            time++;
            idx = generateRandom(0, ticketData.length-1);
            setWinner(ticketData[idx]);
            if( time === 100 ) {
                clearInterval(interval);
                setWinner(ticketData[idx]);
                setIsSaving(false);
            }
        }, 50);
    }
    const confirmWinner = async () => {
        if( isSaving && !winner ) return;
        const response = await fetch(api_url + "/set-winner", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({hash: keyHash, tickets: winner})
          });
          const data = await response.json();
          alert(data.username + ' ' + data.ticket_id + ' ' + data.email + ' ' + data.phone);
    }

    return (
        <div className={styles.profileWrap}>
            <h1>Generate Winner</h1>
            <br />
            <button type="button" onClick={generateWinner}>Generate</button>
            <br /><br />
            {/* <div>
                <label htmlFor='ticketCount'>Count : </label>&nbsp;
                <select value={tCount} id="ticketCount" onChange={(e) => { 
                        setTCount(e.target.value);
                    }}>
                    {[...Array(10)].map((_, i) => (
                        <option key={i} value={i + 1}>
                        {i + 1}
                        </option>
                    ))}
                </select>
            </div><br /> */}
            
            <div>
                <label>Winner: </label>
                {isSaving ? (<span>{winner.ticket_id}</span>) : (
                    <span style={{color:'white'}}>{winner.username + ', ' + winner.ticket_id + ', ' + winner.email + ', ' + winner.phone}</span>
                )}
            </div>

            <br />
            <button type="button" onClick={confirmWinner}>Confirm</button>
            <br />
            
        </div>
    )
}

export default Winner;
