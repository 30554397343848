import { useState, useEffect } from "react"
import HouseImg from "../../../Assets/Images/house1.jpg"
import TicketImg from "../../../Assets/Images/ticket.png"
import { useMediaQuery } from "react-responsive"
import variables from "../../../variables"
import './BestInvSection.sass'

const BestInvSection = (props) => {
    const isDesktopOrLaptop = useMediaQuery({
        query: `(min-width: ${variables.breakpoints.tablet})`,
    })
    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if( window.scrollY > 300 && window.scrollY < 1400 )
                setIsAnimated(true)
            else
                setIsAnimated(false)
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])
    return (
        <section className="bestinv">
            <div className={isDesktopOrLaptop ? (isAnimated ? "text animated fadeInLeft" : "text animated fadeOutLeft") : "text"}>
                <h2>The Best Investment<br /> of your life is ahead</h2>
                {isDesktopOrLaptop && (<p>Get a stunning apartment located in a prime location for 
                an initial investment of just €3! Recently renovated, 
                this apartment requires no further renovation. 
                You have the option to use the apartment yourself, 
                rent it out or if you prefer, we can sell it for you. 
                It's an exceptional opportunity to make a profitable 
                real estate investment with minimal effort.</p>)}
            </div>
            <div className={isAnimated ? "image animated fadeInRight" : "image animated fadeOutRight"}>
                <img src={HouseImg} alt="" className="HouseImg"/>
                <img src={TicketImg} alt="" className="TicketImg"/>
            </div>
            {!isDesktopOrLaptop && (<p>
                Get a stunning apartment located in a prime location for 
                an initial investment of just €3! Recently renovated, 
                this apartment requires no further renovation. 
                You have the option to use the apartment yourself, 
                rent it out or if you prefer, we can sell it for you. 
                It's an exceptional opportunity to make a profitable 
                real estate investment with minimal effort.
            </p>)}
        </section>
    )
}

export default BestInvSection;
