import mapImg from '../../../Assets/Images/map.png'
import apartIco from '../../../Assets/Images/apart_bg1.png'
import { useMediaQuery } from "react-responsive"
import variables from "../../../variables"
import './ApartDetail.sass'

const ApartDetail = (props) => {
    const isDesktopOrLaptop = useMediaQuery({
        query: `(min-width: ${variables.breakpoints.tablet})`,
    })
    return (<>
        <div className="apartSec">
            <div className="apartDetail">
                <div className="left">
                    <h2><strong>Apartment</strong> details</h2>
                    {isDesktopOrLaptop && (<p>The apartment you have a chance to win is nestled in the heart of Central Europe. 
                        With a size of 60 sqm, this cozy living space consists of a spacious living room, 
                        a comfortable bedroom, a modern kitchen, a functional bathroom, and a separate WC. 
                        In addition, the apartment comes with an attic and a cellar for extra storage solutions. 
                        Ideally located, this apartment is currently rented for more than €600 per month, 
                        which testifies to its appeal and value. With a current estimation of €102,000, 
                        this is a real estate opportunity we're offering you to grab.
                    </p>)}
                </div>
                <div className="right">
                    <img src={mapImg} className="mapImg" alt="" />
                </div>
                {!isDesktopOrLaptop && (<p><strong>The apartment you have a chance to win is nestled in 
                        the heart of Central Europe. </strong>
                        With a size of 60 sqm, this cozy living space consists of a spacious living room, 
                        a comfortable bedroom, a modern kitchen, a functional bathroom, and a separate WC. 
                        In addition, the apartment comes with an attic and a cellar for extra storage solutions. 
                        Ideally located, this apartment is currently rented for more than €600 per month, 
                        which testifies to its appeal and value. With a current estimation of €102,000, 
                        this is a real estate opportunity we're offering you to grab.
                    </p>)}
                <img src={apartIco} className="apartImg" alt="" />
            </div>
        </div>
    </>)
}

export default ApartDetail;
