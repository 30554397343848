import { Link } from 'react-router-dom'
import FooterMenu from '../../Components/FooterMenu'
import styles from './styles.module.sass'

const LandingFooter = () => {
    return (
        <footer className={styles.foot}>
            <div className={styles.footerWrap}>
                <FooterMenu />
            </div>
            <div className={styles.footerMenu} id="footerSection">
                <Link to={'/'}>Terms and Conditions</Link>&nbsp;&nbsp;|&nbsp;&nbsp;
                <Link to={'/'}>Privacy Policy</Link>
            </div>
        </footer>
    )
}

export default LandingFooter;