import LandingHeder from "../../Layout/LandingHeader"
import LandingFooter from "../../Layout/LandingFooter"
import BestInvSection from "../../Components/Landing/BestInvSection"
import PlantSlider from "../../Components/Landing/PlantSlider"
import RoomInfo from "../../Components/Landing/RoomInfo"
import ApartDetail from "../../Components/Landing/ApartDetail"
import GrabTicket from "../../Components/Landing/GrabTicket"
import GalxyBalls from "../../Components/Landing/GalxyBalls"
import ParticipateSection from "../../Components/Landing/ParticipateSection"
import FairSection from "../../Components/Landing/FairSection"
import ScrollTop from "../../Components/ScrollTop"
import styles from "./styles.module.sass"

const Landing = () => {
  return (
    <div className={styles.container}>
      <LandingHeder />
      <BestInvSection />
      <PlantSlider />
      <RoomInfo />
      <ApartDetail />
      <ParticipateSection />
      <FairSection />
      <GalxyBalls />
      <GrabTicket />
      <LandingFooter />
      <ScrollTop />
    </div>
  );
};

export default  Landing;