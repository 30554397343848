import styles from './styles.module.sass'
import Menu from '../../Components/Menu'
import LandingFooter from "../../Layout/LandingFooter"
import ScrollTop from "../../Components/ScrollTop"
import { Link } from 'react-router-dom'
import LogoImg from "../../Assets/Images/logo.png"

const AboutUs = () => {
    return (
        <div className={styles.aboutContainer}>
            <header className={styles.galaxyHeader}>
                <Link to={'/'}><img src={LogoImg} className={styles.logoImg} alt="" /></Link>
                <Menu page="galaxy"/>
            </header>
            <h1>About FORMAPRO</h1>
            <p>FORMAPRO is a leading company in the field of digital marketing. 
                Boasting extensive experience and a solid reputation in the industry, 
                our mission is to assist businesses in optimizing their 
                online presence and achieving their marketing goals.</p>
            <p>At FORMAPRO, we understand that the digital world is perpetually evolving. 
                That's why we consistently stay abreast of the latest trends and technologies 
                to ensure that our clients remain at the forefront of their sector.</p>
            <p>Our team of digital marketing experts has diversified skills, 
                including SEO, paid advertising, content marketing, social media, 
                conversion rate optimization, and much more.</p>
            <p>We believe in the power of innovation and creativity to create 
                unique and exciting experiences for users, constantly 
                redefining the possibilities in the digital world.</p>
            <LandingFooter />
            <ScrollTop />
        </div>
    )
}

export default AboutUs;