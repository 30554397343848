import apartIco from '../../../Assets/Images/apart_bg2.png'
import './ParticipateSection.sass'

const ParticipateSection = (props) => {
    return (<>
        <div className="participateSec">
            <div className="partWrap">
                <h2><strong>How to Participate ?</strong></h2>
                <p>To participate in the raffle, you must purchase a ticket. 
                    Each ticket costs 3 euros. When you buy a ticket, 
                    you will also receive 400 UniZ-GalaxyBalls points 
                    that you can use in the mobile application on the day of its release. 
                    The draw will end in 3 months, when all 80 000 tickets have been sold. 
                    The winner will be drawn at random under the supervision of our team 
                    and a Swiss lawyer
                </p>
                <img src={apartIco} className="apartImg" alt="" />
            </div>
        </div>
    </>)
}

export default ParticipateSection;
