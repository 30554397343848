import { useState } from 'react'
import variables from "../../variables"
import { Link } from 'react-router-dom'
import { useMediaQuery } from "react-responsive"
import { Button } from "antd"
import emailImg from "../../Assets/Images/ico_send.png"
import instagramImg from "../../Assets/Images/ico_instagram.png"
import './FooterMenu.sass'
import { api_url } from "../../Constants"
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const FooterMenu = (props) => {
    const [email, setEmail] = useState('');
    const [message, setMessage] = useState('');
    const isDesktopOrLaptop = useMediaQuery({
        query: `(min-width: ${variables.breakpoints.tablet})`,
    })
    const keyHash = process.env.REACT_APP_APP_SECRET_KEY;
    const scrollToTicket = () => {
        console.log(document.documentElement.offsetHeight)
        window.scrollTo({
            top: document.documentElement.offsetHeight,
            behavior: "smooth",
        });
    }

    const notifyError = () => {
        toast.error('Please input correct email address.', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
        });
    }
    const notifySuccess = () => {
        toast.success('Thanks for your contact us!', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
        });
    }

    const sendContactUS = () => {
        if( email !== '' && /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
            fetch(api_url + "/send-contactus", {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify({ hash:keyHash, email:email, msg:message })
            });
            notifySuccess();
            setEmail('');
            setMessage('');
        } else {
            notifyError()
        }
    };
    return (<>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        />
            {/* Same as */}
        <ToastContainer />
        <div className="fooMenus">
            <div className="subItem">
                <h3>About US</h3>
                <p>At <strong>FORMAPRO</strong>, we understand that the digital 
                    world is constantly changing. That's why we're 
                    constantly on the lookout for the latest trends 
                    and technologies to ensure our customers stay 
                    at the forefront of their industry.</p>
            </div>
            <div className="subItem">
                <h3>Quick Links</h3>
                <ul>
                    <li><Link to={'/'}>Home</Link></li>
                    <li><Link to={'/'} onClick={scrollToTicket}>Ticket</Link></li>
                    <li><Link to={'/conditions'}>General conditions</Link></li>
                </ul>
            </div>
            <div className="subItem">
                <h3>Contact US
                    {isDesktopOrLaptop && <Button className='instagramImg' onClick={() => document.location.href='https://www.instagram.com/win_your_home/'}>
                        <img src={instagramImg} alt="" />
                    </Button>}
                </h3>
                <p>Need help? We're here for you!</p>
                <div className="emailForm">
                    <input 
                        className={'emailInput'} type="text" 
                        placeholder='Email address'
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)} />
                </div>
                <div className="emailForm">
                    <textarea 
                        className={'emailMsg'} type="text" 
                        placeholder='Message'
                        value={message} 
                        onChange={(e) => setMessage(e.target.value)} />
                    <Button className='emailBtn' onClick={sendContactUS}>
                        <img src={emailImg} alt="" />
                    </Button>
                    <div className='clear'></div>
                </div>
                <div>
                    {!isDesktopOrLaptop && <Button className='instagramImg' onClick={() => document.location.href='https://www.instagram.com/win_your_home/'}>
                        <img src={instagramImg} alt="" />
                    </Button>}
                </div>
            </div>
        </div>
    </>)
}

export default FooterMenu;
