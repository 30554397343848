import { useEffect } from "react"
import styles from "./styles.module.sass"
import { ArrowRight, FileText, Lock } from "react-feather"
import Input from "../../Components/FormInput"
import { useForm, Controller } from "react-hook-form"
import { useHistory } from 'react-router-dom'
import AuthService from "../../Services/auth.service"
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const Login = () => {
  const history = useHistory()
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if( AuthService.getCurrentUser() !== null ) {
      history.push('/admin')
    }
  }, [history]);

  const notifyError = () => {
    toast.error('Username or Password is incorrect!', {
        position: "top-center",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: false,
        pauseOnHover: false,
        draggable: false,
        progress: undefined,
        theme: "light",
    });
  }

  const onSubmit = ({email, password}) => {
    // AuthService.login(email, password).then(
    //   () => {
    //     this.setState({
    //       redirect: "/profile"
    //     });
    //   },
    //   error => {
    //     const resMessage =
    //       (error.response &&
    //         error.response.data &&
    //         error.response.data.message) ||
    //       error.message ||
    //       error.toString();

    //     this.setState({
    //       loading: false,
    //       message: resMessage
    //     });
    //   }
    // );
    AuthService.login(email, password).then(
      (res) => {
        if( res === 'success')
          history.push('/admin')
        else
          notifyError()
      }
    );
  };

  return (<>
    <ToastContainer
      position="top-right"
      autoClose={5000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      draggable
      pauseOnHover
      theme="light"
      />
      {/* Same as */}
    <ToastContainer />
    <div className={styles.container}>
      <header>
      </header>
      <div className={styles.containerBg}>
        <div className={styles.formContainer}>
          <h1 className={styles.title}>Login</h1>
          <form>
            <Controller
              name="email"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  type={"text"}
                  // value={email}
                  // onChange={(e) => setEmail(e.target.value)}
                  placeholder={"Enter Username"}
                  error={errors.email && "Enter Username"}
                  icon={<FileText color={"#122645"} size={20} />}
                />
              )}
            />
            <Controller
              name="password"
              control={control}
              rules={{ required: true }}
              defaultValue=""
              render={({ field }) => (
                <Input
                  {...field}
                  type={"password"}
                  // value={password}
                  // onChange={(e) => setPassword(e.target.value)}
                  placeholder={"Enter Password"}
                  togglepassword="1"
                  error={errors.password && "Enter Password"}
                  icon={<Lock color={"#122645"} size={20} />}
                />
              )}
            />
            <div className={styles.submit} onClick={handleSubmit(onSubmit)}>
              <span>Login</span>
              <ArrowRight size={20} />
            </div>
          </form>
        </div>
      </div>
    </div>
  </>);
};

export default  Login;