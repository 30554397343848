import { useState, useEffect } from 'react'
import { useMediaQuery } from "react-responsive"
import variables from "../../variables"
import * as Icons from "react-feather"
import './ScrollTop.sass'

const ScrollTop = (props) => {
    const [showButton, setShowButton] = useState(false)
    useEffect(() => {
        const handleScroll = () => {
          if (window.scrollY > 400) {
            setShowButton(true);
          } else {
            setShowButton(false);
          }
        };
      
        window.addEventListener("scroll", handleScroll);
      
        return () => {
          window.removeEventListener("scroll", handleScroll);
        };
    }, []);

    const isDesktopOrLaptop = useMediaQuery({
        query: `(min-width: ${variables.breakpoints.tablet})`,
    })
    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: "smooth",
        });
        setShowButton(false)
    }

    return (<>
        {isDesktopOrLaptop && showButton && (
            <div className="scrollTop" onClick={scrollToTop}>
                <Icons.ChevronUp
                    className={"menu-icon"}
                    color={props.pageName==='galaxy'?"#221e31":"white"}
                    size={50}
                    onClick={scrollToTop}
                />
            </div>
        )}
    </>)
}

export default ScrollTop;
