import ReactDOM from "react-dom";
import App from "./App";
import "./min.css";
import "./index.sass";
import "./ant-overwrite.sass";
import { BrowserRouter as Router } from "react-router-dom";
const root = document.getElementById("root");
if (document.location.protocol !== 'https:') {
  document.location.replace(`https:${document.location.href.substring(document.location.protocol.length)}`);
}
ReactDOM.render(
  <Router>
    <App />
  </Router>,
  root
);
