import { useState, useEffect } from "react"
import { Button } from "antd"
import { useHistory } from 'react-router-dom'
import { api_url } from "../../Constants"
import './SoldTicket.sass'

const SoldTicket = (props) => {
    const [soldPercent, setSoldPercent] = useState(0)
    const history = useHistory()
    const keyHash = process.env.REACT_APP_APP_SECRET_KEY;

    useEffect(() => {
        const fetchGetPercent = async () => {
          const response = await fetch(api_url + "/sell-percent", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ hash:keyHash})
          });
          const data = await response.json();
          if( data ) {
            setSoldPercent(data.sellPercent);
          }
        };
        fetchGetPercent();
    }, [keyHash]);

    return (
        <div className="buy-ticket-wrap">
            <Button className='buyticket-btn' onClick={() => {
                setSoldPercent(0)
                history.push('/pay')
            }}>
                buy ticket 3€
            </Button>
            <div className="progress-section">
                <p>{soldPercent}% TICKETS SOLD</p>
                <div className="progressWrap">
                    <div className="progressBar" style={{width: soldPercent + '%'}} />
                </div>
            </div>
        </div>
    )
}

export default SoldTicket;
