import { useState, useEffect } from 'react'
import styles from './styles.module.sass'
import { api_url } from "../../Constants"
import AuthService from "../../Services/auth.service"

const Profile = () => {
    const keyHash = process.env.REACT_APP_APP_SECRET_KEY;
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    useEffect(() => {
        setUsername(AuthService.getCurrentUser().username);
    }, []);

    const ConfirmUpdate = async () => {
        if (window.confirm("Are you sure you want to update admin info?")) {
            try {
                const result = await fetch(api_url + "/update-admin", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({hash: keyHash, username: username, password: password})
                });
                const data = await result.json();
                if( data.message === 'success' ) {
                    alert('Successfully updated');
                    setPassword('');
                }
            } catch (error) {
                console.error(error);
            }
            
        } else {
            console.log(0)
        }
    }

    return (
        <div className={styles.profileWrap}>
            <h1>Admin Info</h1>
            <div>
                <label htmlFor='username'>Username : </label>&nbsp;
                <input type="text" id="username" value={username} onChange={(e) => setUsername(e.target.value)} />
            </div>
            <br />
            <div>
                <label htmlFor='password'>Password : </label>&nbsp;
                <input type="password" id="password" value={password} onChange={(e) => setPassword(e.target.value)} />
            </div>
            <br />
            <button type="button" onClick={ConfirmUpdate}>Update</button>
        </div>
    )
}

export default Profile;