import { useState } from 'react'
import styles from './styles.module.sass'
import { api_url } from "../../Constants"

const FreeTicket = () => {
    const keyHash = process.env.REACT_APP_APP_SECRET_KEY;
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [tCount, setTCount] = useState(1);
    const [isSaving, setIsSaving] = useState(false);

    const ConfirmUpdate = async () => {
        if( isSaving ) return;
        if( username !== '' && email !== '' && phone !== '' ) {
            setIsSaving(true);
            try {
                const result = await fetch(api_url + "/gen-ticket", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({hash: keyHash, username: username, email: email, phone: phone, count: tCount})
                });
                const data = await result.json();
                if( data.ticketId ) {
                    alert('New tickets is generated');
                    setUsername('');
                    setPhone('');
                    setEmail('');
                    setTCount(1);
                    setIsSaving(false);
                }
            } catch (error) {
                console.error(error);
            }
        } else {
            alert('Please input username, email, phone number.');
        }
    }

    return (
        <div className={styles.profileWrap}>
            <h1>Free Ticket</h1>
            <div>
                <label htmlFor='username'>Username : </label>&nbsp;
                <input type="text" id="username" value={username} onChange={(e) => setUsername(e.target.value)} />
            </div>
            <br />
            <div>
                <label htmlFor='email'>Email : </label>&nbsp;
                <input type="text" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            </div>
            <br />
            <div>
                <label htmlFor='phone'>Phone : </label>&nbsp;
                <input type="text" id="phone" value={phone} onChange={(e) => setPhone(e.target.value)} />
            </div>
            <br />
            <div>
                <label htmlFor='ticketCount'>Ticket Count : </label>&nbsp;
                <input type="number" id="ticketCount" value={tCount} onChange={(e) => setTCount(e.target.value)} />
            </div>
            <br />
            <button type="button" onClick={ConfirmUpdate}>Generate</button>
        </div>
    )
}

export default FreeTicket;
