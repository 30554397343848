import phone3 from '../../../Assets/Images/phone3.jpg'
import phone4 from '../../../Assets/Images/phone4.jpg'
import CoinImg1 from '../../../Assets/Images/img_coin1.png'
import CoinImg2 from '../../../Assets/Images/img_coin2.png'
import CoinImg3 from '../../../Assets/Images/img_coin3.png'
import CoinImg4 from '../../../Assets/Images/img_coin4.png'
import CoinImg5 from '../../../Assets/Images/img_coin5.png'
import GalaxyBox1 from '../../../Assets/Images/img_box1.jpg'
import GalaxyBox2 from '../../../Assets/Images/img_box2.jpg'
import './GalaxyImg.sass'

const GalaxyImg = (props) => {
    return (<>
        <div className="galaxyImgWrap">
            <div className="subWrap">
                <img src={phone3} alt="" className="phoneImg" />
                <img src={CoinImg1} alt="" className="CoinImg1" />
                <img src={CoinImg2} alt="" className="CoinImg2" />
                <img src={CoinImg3} alt="" className="CoinImg3" />
                <img src={CoinImg4} alt="" className="CoinImg4" />
                <img src={GalaxyBox1} alt="" className="GalaxyBox1" />
            </div>
            <div className="subWrap">
                <img src={phone4} alt="" className="phoneImg" />
                <img src={CoinImg5} alt="" className="CoinImg5" />
                <img src={CoinImg4} alt="" className="CoinImg6" />
                <img src={CoinImg3} alt="" className="CoinImg7" />
                <img src={CoinImg2} alt="" className="CoinImg8" />
                <img src={CoinImg5} alt="" className="CoinImg9" />
                <img src={GalaxyBox2} alt="" className="GalaxyBox2" />
            </div>
        </div>
    </>)
}

export default GalaxyImg;
