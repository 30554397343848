import bedRoomImg from "../../../Assets/Images/ico_bed.png";
import sofaImg from "../../../Assets/Images/ico_sofa.png";
import bathRoomImg from "../../../Assets/Images/ico_bath.png";
import storeImg from "../../../Assets/Images/ico_store.png";
import kitchenImg from "../../../Assets/Images/ico_kitchen.png";
import roomImg from "../../../Assets/Images/ico_room.png";
import './RoomInfo.sass'

const RoomInfo = (props) => {
    return (
        <div className="roominfoSec">
            <h2>ROOM INFORMATION</h2>
            <div className="infoWrap">
                <div className="infoItem">
                    <h4>Bedroom</h4>
                    <img src={bedRoomImg} alt="" />
                    <p><span>1 Large double bed</span></p>
                </div>
                <div className="infoItem">
                    <h4>Living room</h4>
                    <img src={sofaImg} alt="" />
                    <p><span>1 Sofa</span></p>
                </div>
                <div className="infoItem longWidth">
                    <h4>Additional rooms</h4>
                    <img src={bathRoomImg} alt="" />
                    <img src={kitchenImg} alt="" />
                    <img src={storeImg} alt="" />
                    <img src={roomImg} alt="" />
                    <p>
                        <span>1 Bathroom</span>
                        <span>1 Store room</span>
                    </p>
                    <p>
                        <span>1 Kitchen</span>
                        <span>1 Other room</span>
                    </p>
                </div>
            </div>
            <h3>Total Surface:</h3>
            <br />
            <h3 className="sqrTitle">60m²</h3>
        </div>
    )
}

export default RoomInfo;
