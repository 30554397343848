import { useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { useMediaQuery } from "react-responsive"
import variables from "../../variables"
import * as Icons from "react-feather"
import './Menu.sass'

const Menu = (props) => {
    const location = useLocation()
    const [showMenu, setShowMenu] = useState(false)
    const isDesktopOrLaptop = useMediaQuery({
        query: `(min-width: ${variables.breakpoints.tablet})`,
    })
    const showMenuFunc = () => {
        setShowMenu(!showMenu)
    }
    const showSearchFunc = () => {
        props.setShowSearch(!props.showSearch)
    }
    const scrollToBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: "smooth",
        });
    }
    const scrollToTicket = () => {
        console.log(document.documentElement.offsetHeight)
        window.scrollTo({
            top: document.documentElement.offsetHeight,
            behavior: "smooth",
        });
    }

    return (<>
        {!isDesktopOrLaptop && (
            <div className="mobileHeader">
                {showMenu ? (
                    <Icons.X
                        className={"menu-icon"}
                        color={"white"}
                        onClick={showMenuFunc}
                    />
                ) : (
                    <Icons.Menu
                        className={"menu-icon"}
                        color={"white"}
                        onClick={showMenuFunc}
                    />
                )}
                {props.page !== 'galaxy' ? (
                    <Icons.Search
                        className={"menu-icon"}
                        color={"white"}
                        onClick={showSearchFunc}
                    />
                ) : null}
            </div>
        )}
        <div className={showMenu?'menus showMenu':'menus'}>
            <ul>
                <li className={location.pathname === '/home'?'active':''}><Link to={'/home'}>Home</Link></li>
                <li className={location.pathname === '/conditions'?'active':''}><Link to={'/conditions'}>General Conditions</Link></li>
                <li className={location.pathname === '/galaxy'?'active':''}><Link to={'/galaxy'}>Galaxyballs</Link></li>
                <li><Link to={'/'} onClick={scrollToTicket}>Ticket</Link></li>
                <li className={location.pathname === '/about'?'active':''}><Link to={'/about'}>About us</Link></li>
                <li><span onClick={scrollToBottom}>Contact us</span></li>
            </ul>
        </div>
        <div className="clear"></div>
    </>)
}

export default Menu;
