import { useEffect } from 'react'
import styles from './styles.module.sass'
import Menu from '../../Components/Menu'
import LandingFooter from "../../Layout/LandingFooter"
import ScrollTop from "../../Components/ScrollTop"
import { Link } from 'react-router-dom'
import LogoImg from "../../Assets/Images/logo.png"

const Conditions = () => {
    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);
    return (
        <div className={styles.conditionContainer}>
            <header className={styles.galaxyHeader}>
                <Link to={'/'}><img src={LogoImg} className={styles.logoImg} alt="" /></Link>
                <Menu page="galaxy"/>
            </header>
            <h1>General conditions</h1>
            <p>Terms & Conditions for the purchase of coupons for 400 UNIZ points 
                and the separate offer of a prize draw for an apartment</p>
            <p>PLEASE READ THESE TERMS & CONDITIONS CAREFULLY.</p>
            <p>BY USING THIS WEBSITE, SIGNING UP FOR AN ACCOUNT, 
                PURCHASING COUPONS FOR 400 UNIZ POINTS OR PARTICIPATING 
                IN THE PROJECT, YOU ARE AGREEING TO THESE TERMS & CONDITIONS.</p>
            <p>LAST UPDATED ON JUNE 22TH, 2023.</p>
            <ol>
                <li>Introduction: These terms and conditions (the “Terms”) 
                    set forth the legally binding terms for the purchase of 
                    a coupon for 400 points called UniZ (the “UniZ Points”) 
                    to be used in the GalaxyBalls game application and for 
                    the separate offer of a prize draw for an apartment (the “Project”), 
                    which is carried out by FORMAPRO LTD (the “Organizer”). 
                    Further, the Terms apply to the use of the website located 
                    at <a href="https://www.winyourhome.co.uk" title="www.winyourhome.co.uk">https://www.winyourhome.co.uk</a> (the “Website”), 
                    its content and services, and form an integral part of 
                    the contractual relationship between the Organizer and 
                    the buyer or the prize draw participant (the “Participant”), 
                    respectively. By registration for participation in the Project, 
                    the Participant accepts these Terms and represents and warrants 
                    that he or she has the right, authority and capacity to enter 
                    the contractual relationship with the Organizer.</li>
                <li>Terms of use in connection with the gaming app: The terms of 
                    use and privacy policy of the gaming app, including any 
                    updates or changes, apply to the use of the gaming app 
                    and must be complied with by users. These terms can be accessed 
                    separately and are accepted by purchasing the UniZ Points.</li>
                <li>Eligibility: By accessing the Website, registering for an account, 
                    purchasing coupons of 400 UniZ Points, or participating in 
                    the Project, the Participant states that the following facts 
                    are accurate: a&#41; the Participant is at least 18-years old, 
                    have reached the age of majority where he or she resides, 
                    and have the legal capacity to agree to these Terms; 
                    b&#41; the Participant is not a resident of Switzerland and the 
                    United States; c&#41; the Participant is not an employee, agent 
                    or representative of the Organizer or any of its affiliates; 
                    d&#41; the Participant has complied with and will continue to comply 
                    with the laws of the jurisdiction from which he or she accesses 
                    the Website; e&#41; all information and data submitted by the 
                    Participant for registration is accurate, and will be promptly 
                    updated if it becomes inaccurate later.</li>
                <li>Participation: Participants may participate in the Project by purchasing 
                    a coupon of 400 UniZ Points on the Organizer&apos;s Website. 
                    Each coupon purchased contains a ticket number for the prize draw. 
                    Participants may purchase as many coupons as they wish, subject to availability.</li>
                <li>Prize draw: A draw will be conducted by the Organizer to determine the winner 
                    of the offered apartment. The draw will be conducted under the supervision 
                    of an independent Attorney. The winning number will be selected randomly.</li>
                <li>Allocation of the apartment: The offered apartment will be allocated to 
                    the Participant whose ticket number corresponds to the winning number 
                    selected in the prize draw. The apartment will be provided as a gift 
                    and thus acquired free of charge, subject to the taxes, charges, 
                    and fees pursuant to section 7.</li>
                <li>Property transfer of the apartment: The winner is responsible for 
                    all taxes, charges and fees associated with the transfer of the apartment, 
                    including real estate transfer tax, real estate tax and registration 
                    fees in the property register.</li>
                <li>Entitlement to the prize: The prize cannot be assigned or exchanged 
                    for other prizes or services. If the winner wishes, he can choose 
                    to be paid the equivalent in money instead of the apartment.</li>
                <li>Ownership and use of coupons for 400 UniZ Points: The buyer of coupons 
                    for 400 UniZ Points acquires unlimited ownership of the purchased coupon, 
                    subject to the limitations and conditions in these Terms.</li>
                <li>Role of the Attorney: An independent attorney (the “Attorney”) will be present 
                    as a witness at the prize draw. Her role is to monitor the integrity and 
                    transparency of the draw process.</li>
                <li>Role of P-TRON TECH: (the “P-TRON TECH”) is an independent Swiss software 
                    company with its registered seat in Switzerland responsible for the 
                    development and maintenance of the gaming app.</li>
                <li>Collaboration with the UK Company: The Project will be carried out in 
                    collaboration with an independent company based in the United Kingdom 
                    of Great Britain and Northern Ireland (the “UK Company”).</li>
                <li>Promotion and collaboration with the partner company: P-TRON TECH and 
                    a partner company based in the United Kingdom of Great Britain work 
                    together exclusively in the context of the promotion and development 
                    of the gaming app. They are not involved in the organization and / or 
                    implementation of the apartment draw. All related actions and decisions 
                    are the sole responsibility of the Organizer of the Project.</li>
                <li>Separation of promotion and prize draw: The promotion and sale of 
                    coupons for 400 UniZ Points and / or UniZ Points are separate 
                    from the draw of the apartment.</li>
                <li>Warranty and guarantee: The Attorney and the Swiss Software Company 
                    P-TRON TECH do not warrant, guarantee or assume liability for the 
                    accuracy, completeness and actuality of the information and data 
                    in connection with the Project and the apartment drawing. 
                    The Organizer warrants that the apartment exists, is not subject 
                    to any restraints on disposal and is free from any mortgages. 
                    Any warranty and liability beyond this is excluded to the extent 
                    permitted by law.</li>
            </ol>
            <p>Warranty and guarantee: The Attorney and P-TRON TECH do not warrant and 
                guarantee and make no representations in connection with the Project 
                and the draw for the apartment. However, they commit themselves to the 
                greatest possible transparency with regard to the Project and the draw 
                for the apartment. The Organizer warrants that the apartment drawn exists, 
                is not subject to any restrictions on disposal and is free from mortgages. 
                All other warranties and guarantees are excluded to the fullest extent permitted 
                by law. This clause is intended to provide peace of mind to Participants 
                taking part in the Project.</p>
            <ol>
                <li>Limit on Liability: Unless caused by gross negligence or willful misconduct, 
                    the Organizer, the Attorney and P-TRON TECH will not be liable for any damages 
                    (including, but not limited to, direct, indirect, special 
                    (including so-called consequential damages), 
                    statutory, punitive damages) arising out of access or inability to access 
                    the Website or its content, purchasing coupon and / or UniZ Points, 
                    participating in the Project or accepting and using the offered apartment, 
                    whether based on contract, tort or any other legal ground.</li>
            </ol>
            <p>Subject to the mandatory law provisions, the Organizer, the Attorney and P-TRON 
                TECH also will not be liable to the Participant for any damages, including, 
                but not limited to (1) personal injury, (2) pain and suffering, 
                (3) emotional distress, (4) loss of revenue, (5) loss of profits, 
                (6) loss of business or anticipated savings, (7) loss of use, 
                (8) loss of goodwill, (9) loss of data, (10) loss of privacy, or 
                (11) computer failure related to the access of or the inability to 
                access the Website or the content, to the purchasing coupon and / or UniZ Points, 
                participating in the Project or accepting and using the offered apartment.</p>
            <p>The exclusions set out in this section 16. apply regardless of theory of liability 
                and even if the Participant told the Organizer, the Attorney and P-TRON TECH 
                about the possibility of these damages or they knew or should have known 
                about the possibility of these damages.</p>
            <p>Subject to the mandatory law provisions, the maximum liability to the Participant 
                for any claim will not extend EUR 100.-.</p>
            <p>The above disclaimers, exclusions, and limits apply to the greatest extent allowed 
                by law, but no more. It is not intended to deprive the Participant of any 
                mandatory protections provided by law. Because some jurisdictions may prohibit 
                the disclaimer of some warranties, the exclusion of some damages, or other matters, 
                one or more of the disclaimers, exclusions, or limits might not apply to the Participant.</p>
            <ol>
                <li>Force Majeure: The Organizer, the Attorney and P-TRON TECH are not responsible 
                    for any failure to perform if unforeseen circumstances or causes beyond their 
                    reasonable control delays or continues to delay the performance.</li>
                <li>Data protection: The Organizer, the Attorney as well as P-TRON TECH undertake 
                    to protect the personal data of the Participants in accordance with the laws 
                    and regulations in force in the field of data protection.</li>
                <li>Entire Agreement: These Terms and other documents available on the Website, 
                    constitute the entire agreement between the Participant and the Organizer. 
                    It supersedes all earlier or contemporaneous Terms between the Participant 
                    and the Organizer.</li>
                <li>Changes: The Organizer may change these Terms on one or more occasions. 
                    Changes will not apply to continuing disputes or to disputes arising out 
                    of or relating to events happening before the posted changes. While the 
                    Organizer will try to notify the Participant when the changes are made to 
                    these Terms, the Organizer do not assume an obligation to do so, and it is 
                    the Participant&apos;s responsibility to frequently check the Website to review 
                    the most current Terms. By continuing to use the Website after the changes 
                    to these Terms were posted, the Participant agrees to the revised Terms.</li>
                <li>Assignment and Delegation: The Organizer may assign its rights or delegate 
                    any performance under these Terms. The Participant will not assign his or her 
                    rights or delegate his or her performance under these Terms without first 
                    obtaining the Organizer&apos;s advanced written consent. Any attempted assignment 
                    of rights or delegation of performance in breach of this section 21. is void.</li>
                <li>Severability: If any provision of these Terms is held to be unenforceable, 
                    then that provision will be modified to the minimum extent necessary to make 
                    it enforceable, unless that modification is not permitted by law, in which 
                    case that provision will be disregarded, whilst the rest of the Terms will 
                    remain in effect. If modifying or disregarding the unenforceable provision 
                    would result in failure of an essential purpose of the contractual relationship 
                    between the parties, the entire agreement will be held unenforceable.</li>
                <li>No Third-Party Beneficiaries: These Terms do not, and the parties do 
                    not intend them to confer any rights or remedies on any person other 
                    than the parties to these Terms.</li>
                <li>Governing Law and place of jurisdiction: All matters arising out of or relating 
                    to these Terms are governed by the substantive laws of the United Kingdom of 
                    Great Britain and Northern Ireland &#8211; with the exclusion of its conflicts of 
                    law rules and the Vienna Convention on the International Sale of Goods, 
                    dated April 11, 1980 &#8211; subject to the exemptions provided by applicable 
                    mandatory law provisions. The parties expressly agree that the place of 
                    jurisdiction is the United Kingdom of Great Britain and Northern Ireland, 
                    subject to the exemptions provided by applicable mandatory law provisions.</li>
                <li>Dispute Resolution: a&#41; Jury Trial Waiver: If the laws of any party&apos;s 
                    jurisdiction provide for the right to a trial by jury, the following 
                    shall apply: Both parties hereby waive the right to a trial by jury for 
                    any claim arising out of or relating to the Website or these Terms. 
                    Either party may enforce this waiver up to and including the first day of 
                    trial. b&#41; Class Action Waiver: If the laws of any party&apos;s jurisdiction provide 
                    for a class action or comparable actions, the following shall apply: 
                    The parties will conduct any proceedings to resolve a dispute in any 
                    forum on an individual basis only. Neither party will try to have any 
                    dispute heard as a class action or in any other proceeding in which either 
                    party acts or proposes to act in a representative capacity.</li>
                <li>Contact: If you have any questions or concerns regarding the Project or 
                    these Terms, you may contact the Organizer, FORMAPRO LTD. The contact 
                    information can be found on the Website of the Organizer.</li>
                <li>English language: We have drafted these Terms in the English and some other 
                    languages. We assume that you can read and understand the English language. 
                    The English language version controls over any translated version.</li>
            </ol>
            <br />
            <LandingFooter />
            <ScrollTop />
        </div>
    )
}

export default Conditions;
