import { Layout } from 'antd'
import styles from './styles.module.sass'
import { Link } from 'react-router-dom'
import LogoImg from "../../Assets/Images/logo.png"
import { useHistory } from 'react-router-dom'
import AuthService from '../../Services/auth.service'
const { Header } = Layout

const HeaderComponent = () => {
    const history = useHistory()
    const logout = () => {
        AuthService.logout()
        history.push('/home')
    }
    return (
        <Header className={styles.header}>
            <Link to={'/home'}><img src={LogoImg} className={styles.logoImg} alt="" /></Link>
            <ul className={styles.adminMenu}>
                <li><Link to={'/admin'}>Tickets</Link></li>
                <li><Link to={'/free'}>Free Ticket</Link></li>
                <li><Link to={'/profile'}>Profile</Link></li>
                <li><Link to={'/winner'}>Winner</Link></li>
            </ul>
            <button type="button" className={styles.logout} onClick={logout}>Logout</button>
        </Header>
    )
}

export default HeaderComponent;
