import React, { useMemo, useEffect, useState } from "react"
import styles from './styles.module.sass'
import Menu from '../../Components/Menu'
import LandingFooter from "../../Layout/LandingFooter"
import ScrollTop from "../../Components/ScrollTop"
import { Link, useHistory } from 'react-router-dom'
import LogoImg from "../../Assets/Images/logo.png"
import Loader from "../../Assets/Images/loader.svg"
import { api_url } from "../../Constants"

import {
  useStripe,
  useElements,
  CardNumberElement,
  CardCvcElement,
  CardExpiryElement
} from '@stripe/react-stripe-js'

const useOptions = () => {
    const options = useMemo(
      () => ({
        style: {
          base: {
            fontSize: '18px',
            padding: '15px',
            backgroundColor: 'white',
            color: "#000",
            letterSpacing: "0.025em",
            fontFamily: "Source Code Pro, monospace",
            "::placeholder": {
              color: "#666"
            }
          },
          invalid: {
            color: "#9e2146"
          }
        }
      }), []
    );
  
    return options;
};

const Payment = () => {
    const appearance = {
        theme: 'night',
        labels: 'floating'
    };

    const stripe = useStripe();
    const options = useOptions();
    const elements = useElements({appearance});

    const history = useHistory();

    const keyHash = process.env.REACT_APP_APP_SECRET_KEY;

    const [cSecret, setCSecret]     = useState('');
    const [userId, setUserId]       = useState(0);
    const [userName, setUserName]   = useState('');
    const [email, setEmail]         = useState('');
    const [phoneNum, setPhoneNum]   = useState('');
    const [tCount, setTCount]       = useState(1);
    const [isLoading, setIsLoading] = useState(false);
    const [isModal, setIsModal]     = useState(false);

    useEffect(() => {
        const fetchClientSecret = async () => {
          const response = await fetch(api_url + "/create-intent", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ hash:keyHash, count:1 })
          });
          const data = await response.json();
          if( data ) {
            setCSecret(data.client_secret);
            setUserId(data.recent_user_id);
          } 
        };
        fetchClientSecret();
    }, [keyHash]);

    const generatePaymentIntent = async (selCount) => {
      setTCount(selCount);
        const response = await fetch(api_url + "/create-intent", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ hash:keyHash, count:selCount })
          });
          const data = await response.json();
          if( data ) {
            setCSecret(data.client_secret);
            setUserId(data.recent_user_id);
          } 
    }

    // const elements = stripe.elements({cSecret, appearance});

    const handleSubmit = async (event) => {
        event.preventDefault();
    
        if( !stripe || !elements ) {
            return;
        }

        if( userName === '' || email === '' || phoneNum === '' ) {
            return;
        }

        setIsLoading(true);
    
        const result = await stripe.confirmCardPayment(cSecret, {
            payment_method: {
                card: elements.getElement(CardNumberElement),
                billing_details: {
                    name: userName,
                    phone: phoneNum
                },
            },
        });
    
        if (result.error) {
            console.error(result.error);
            alert(result.error.message);
        } else {
            if( result.paymentIntent.status === "succeeded" ) {
                fetch(api_url + "/create-info", {
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    body: JSON.stringify({
                        hash: keyHash, userId: userId,
                        username: userName, email: email,
                        phone: phoneNum, count: tCount
                    })
                });
                showModal();
            }
         }
        setIsLoading(false);
    };

    const showModal = () => {
        setIsModal(true);
    };
    
    const handleOk = () => {
        setIsModal(false);
        history.push('/home');
    };

    return (<>
        <div className={styles.paymentContainer}>
            <header className={styles.galaxyHeader}>
                <Link to={'/'}><img src={LogoImg} className={styles.logoImg} alt="" /></Link>
                <Menu page="galaxy"/>
            </header>
            <h1>Pay {3 * tCount}€</h1>
            <form onSubmit={handleSubmit} className={styles.paymentForm}>
                <label>User Name</label>
                <div className={styles.formInputWrap}>
                    <input
                        value={userName}
                        placeholder="User Name"
                        onChange={e => setUserName(e.target.value)}
                        type={'text'}
                        className={styles.input}
                    />  
                </div>
                <label>Email</label>
                <div className={styles.formInputWrap}>
                    <input
                        value={email}
                        placeholder="Email"
                        onChange={e => setEmail(e.target.value)}
                        type={'text'}
                        className={styles.input}
                    />  
                </div>
                <label>Phone Number</label>
                <div className={styles.formInputWrap}>
                    <input
                        value={phoneNum}
                        placeholder="Phone Number"
                        onChange={e => setPhoneNum(e.target.value)}
                        type={'text'}
                        className={styles.input}
                    />  
                </div>
                <label>Ticket Count</label>
                <div className={styles.formInputWrap}>
                    <select value={tCount} onChange={(e) => { 
                        setTCount(e.target.value);
                        generatePaymentIntent(e.target.value);
                    }}>
                    {[...Array(99)].map((_, i) => (
                        <option key={i} value={i + 1}>
                        {i + 1}
                        </option>
                    ))}
                    </select>
                </div>
                <label>Card number</label>
                <div className={styles.formInputWrap}>
                    <CardNumberElement
                        options={options}
                        onReady={() => {
                            console.log("CardNumberElement [ready]");
                        }}
                        onChange={event => {
                            console.log("CardNumberElement [change]", event);
                        }}
                        onBlur={() => {
                            console.log("CardNumberElement [blur]");
                        }}
                        onFocus={() => {
                            console.log("CardNumberElement [focus]");
                        }}
                    />
                </div>

                <label>Expiration date</label>
                <div className={styles.formInputWrap}>
                    <CardExpiryElement
                        options={options}
                        onReady={() => {
                            console.log("CardNumberElement [ready]");
                        }}
                        onChange={event => {
                            console.log("CardNumberElement [change]", event);
                        }}
                        onBlur={() => {
                            console.log("CardNumberElement [blur]");
                        }}
                        onFocus={() => {
                            console.log("CardNumberElement [focus]");
                        }}
                    />
                </div>
                
                <label>CVC</label>
                <div className={styles.formInputWrap}>
                    <CardCvcElement
                        options={options}
                        onReady={() => {
                            console.log("CardNumberElement [ready]");
                        }}
                        onChange={event => {
                            console.log("CardNumberElement [change]", event);
                        }}
                        onBlur={() => {
                            console.log("CardNumberElement [blur]");
                        }}
                        onFocus={() => {
                            console.log("CardNumberElement [focus]");
                        }}
                    />
                </div>
                <br />
                <button type="submit" className={styles.buyBtn} disabled={!stripe || !elements}>
                    {isLoading ? <img src={Loader} className={styles.spinner} alt="" /> : 'Pay ' + (tCount * 3) + '€'}
                </button>
            </form>
            <LandingFooter />
            <ScrollTop />
        </div>
        {isModal && 
        <div className={styles.successModal}>
            <div className={styles.modalContent}>
                <div className={styles.checkWrap}><span></span></div>
                <h5>Success</h5>
                <p>Congratulations! Your payment has been successfully processed. 
                    Please check your email for your confirmation.</p>
                <button type="button" onClick={handleOk}>OK</button>
            </div>
        </div>
        }
    </>)
}

export default Payment;
