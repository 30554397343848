import React, { useState } from 'react'
import { Search } from "react-feather"
import { Button } from "antd"
import variables from "../../variables"
import { api_url } from "../../Constants"
import { useMediaQuery } from "react-responsive"
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import './Search.sass'

const SearchBox = (props) => {
    const [search, setSearch] = useState('');
    const [isSearch, setIsSearch] = useState(false);
    const keyHash = process.env.REACT_APP_APP_SECRET_KEY;

    const isDesktopOrLaptop = useMediaQuery({
        query: `(min-width: ${variables.breakpoints.tablet})`,
    })

    const notifyError = () => {
        toast.error('The ticket id is invalid', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: false,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
        });
    }
    const notifySuccess = () => {
        toast.success('The ticket id is valid', {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "light",
        });
    }
    const checkTicketId = async (event) => {
        event.preventDefault();
        if( isSearch ) return;

        setIsSearch(true);

        const response = await fetch(api_url + "/validate", {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({hash: keyHash, ticket_id: search})
        });
        const data = await response.json();
        setIsSearch(false);
        console.log(data.ticket)
        if( data.ticket.length > 0 ) {
            notifySuccess();
        } else {
            notifyError();
        }
        // search -> ticket id
    }

    return (<>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
            />
            {/* Same as */}
        <ToastContainer />
        {!isDesktopOrLaptop && 
            <div className={props.showSearch ? "search-wrap animated fadeInRight" : "search-wrap animated fadeOutRight"}>
                <form>
                    <input 
                        className={'search'} type="text" 
                        placeholder='Search serial number'
                        value={search} 
                        onChange={(e) => setSearch(e.target.value)} />
                    <Button className='search-btn' onClick={checkTicketId}>
                        <Search color={'white'}  className={''}/>
                    </Button>
                </form>
            </div>
        }
        {isDesktopOrLaptop && 
            <div className={"search-wrap"}>
                <form>
                    <input 
                        className={'search'} type="text" 
                        placeholder='Search serial number'
                        value={search} 
                        onChange={(e) => setSearch(e.target.value)} />
                    <Button className='search-btn' onClick={checkTicketId}>
                        <Search color={'white'}  className={''}/>
                    </Button>
                </form>
            </div>
        }
    </>)
}

export default SearchBox;
