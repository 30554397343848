import GrabBg from '../../../Assets/Images/grap_bg.jpg'
import './GrabTicket.sass'

const GrabTicket = (props) => {
    return (
        <div className="grabSec">
            <img src={GrabBg} className="GrabBg" alt="" />
        </div>
    )
}

export default GrabTicket;
