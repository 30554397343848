import { useState, useEffect } from 'react'
import styles from './styles.module.sass'
import { api_url } from "../../Constants"
import { Table } from "antd"

const Dashboard = () => {
    const keyHash = process.env.REACT_APP_APP_SECRET_KEY;
    const [ticketData, setTicketData] = useState([]);
    const [search, setSearch] = useState('');
    useEffect(() => {
        const fetchClientSecret = async () => {
            const response = await fetch(api_url + "/get-tickets", {
              method: "POST",
              headers: { "Content-Type": "application/json" },
              body: JSON.stringify({hash: keyHash})
            });
            const data = await response.json();
            if( data ) {
                setTicketData(data)
            } 
        };
        fetchClientSecret();
    }, [keyHash]);

    /* const setTicketStatus = (ticketId, value) => {
        console.log(ticketId + ' ' + value);
    } */

    const columns = [
        {
            key: 'username',
            title: 'User Name',
            dataIndex: 'username'
        },
        {
            key: 'ticket_id',
            title: 'Ticket ID',
            dataIndex: 'ticket_id'
        },
        {
            key: 'email',
            title: 'Email',
            dataIndex: 'email'
        },
        {
            key: 'phone',
            title: 'Phone',
            dataIndex: 'phone'
        },
        {
            key: 'points',
            title: 'Uniz Point',
            dataIndex: 'points'
        },
        /* {
            key: 'valid',
            title: 'Status',
            render: (record) => {
                const validStatus = record.valid;
                const selTicketId = record.ticket_id;
                return (<>
                    <select onChange={(e) => setTicketStatus(selTicketId, e.target.value)}>
                        {validStatus && <>
                            <option value="0">Invalid</option>
                            <option value="1" selected>Valid</option>
                        </>}
                        {!validStatus && <>
                            <option value="0" selected>Invalid</option>
                            <option value="1">Valid</option>
                        </>}
                    </select>
                </>)
            }
        }, */
    ];
    return (
        <div className={styles.adminTicketWrap}>
            <h1>Tickets</h1>
            <input 
                className={styles.ticketSearch}
                type="text" placeholder="Username or Email" 
                value={search} 
                onChange={(e) => setSearch(e.target.value)}
            />
            <Table
                columns={columns}
                dataSource={ticketData.filter(item => {return item.username.includes(search) || item.email.includes(search)})}
                pagination={{ pageSize:20 }}
            />
        </div>
    )
}

export default Dashboard;
