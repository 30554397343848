import GalaxyHeader from "../../Layout/GalaxyHeader"
import LandingFooter from "../../Layout/LandingFooter"
import ScrollTop from "../../Components/ScrollTop"
import GalaxyImg from "../../Components/Galaxy/GalaxyImg"
import TakeYourTicket from "../../Components/Galaxy/TakeYourTicket"
import styles from "./styles.module.sass"

const Galaxy = () => {
  return (
    <div className={styles.container}>
      <GalaxyHeader />
      <GalaxyImg />
      <TakeYourTicket />
      <LandingFooter />
      <ScrollTop pageName="galaxy"/>
    </div>
  );
};

export default  Galaxy;