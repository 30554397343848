import React, { useEffect } from "react";
import { Layout } from "antd";
import { useMediaQuery } from "react-responsive";

import Header from "./Layout/Header";
import Footer from "./Layout/Footer";
import Dashboard from "./Page/Dashboard"
import Profile from "./Page/Profile"
import FreeTicket from "./Page/FreeTicket"
import Winner from "./Page/Winner"
import { Switch, Route, useHistory } from "react-router-dom";
import variables from "./variables";
import "./App.sass";
import AuthService from "./Services/auth.service"

const { Content } = Layout;

const AppHelper = () => {
  const isDesktopOrLaptop = useMediaQuery({
    query: `(min-width: ${variables.breakpoints.tablet})`,
  });
  const history = useHistory();

  useEffect(() => {
    if( AuthService.getCurrentUser() == null ) {
      history.push('/home')
    } else {
      history.push('/admin')
    }
  }, [history]);

  return (
    <>
      <Layout className={"main-layout"}>
        <Layout className="site-layout">
          <Header />
          <Content
            className="site-layout-background"
            style={{
              background: "#10101A",
            }}
          >
            <Switch>
              <Route path={"/admin"} exact>
                <Dashboard />
              </Route>
              <Route path={"/profile"} exact>
                <Profile />
              </Route>
              <Route path={"/free"} exact>
                <FreeTicket />
              </Route>
              <Route path={"/winner"} exact>
                <Winner />
              </Route>
            </Switch>
          </Content>
          {!isDesktopOrLaptop && <Footer />}
        </Layout>
      </Layout>
    </>
  );
};

export default AppHelper;
