import { useState, useEffect } from "react"
import GalaxyImg from '../../../Assets/Images/gal_text.png'
import GalaxyImg1 from '../../../Assets/Images/gal_img1.png'
import GalaxyImg2 from '../../../Assets/Images/gal_img2.png'
import GalaxyImg3 from '../../../Assets/Images/gal_img3.png'
import AndroidBtnImg from '../../../Assets/Images/btn_android.png'
import IOSBtnImg from '../../../Assets/Images/btn_ios.png'
import { Button } from "antd"
import { useMediaQuery } from "react-responsive"
import variables from "../../../variables"
import './GalxyBalls.sass'

const GalxyBalls = (props) => {
    const isDesktopOrLaptop = useMediaQuery({
        query: `(min-width: ${variables.breakpoints.tablet})`,
    })

    const [isAnimated, setIsAnimated] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            if( window.scrollY > 3980 && window.scrollY < 4600 )
                setIsAnimated(true)
            else
                setIsAnimated(false)
        }

        window.addEventListener('scroll', handleScroll)

        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    return (
        <div className="galaxySec">
            <img src={GalaxyImg} className={isAnimated ? "GalaxyImg animated pulse" : "GalaxyImg"} alt="" />
            <img src={GalaxyImg1} className={isDesktopOrLaptop ? (isAnimated ? "GalaxyImg1 animated bounceInLeft" : "GalaxyImg1 animated bounceOutLeft") : "GalaxyImg1"} alt="" />
            <img src={GalaxyImg2} className={isDesktopOrLaptop ? (isAnimated ? "GalaxyImg2 animated rotateInDownLeft" : "GalaxyImg2 animated rotateOutUpLeft") : "GalaxyImg2"} alt="" />
            <img src={GalaxyImg3} className={isDesktopOrLaptop ? (isAnimated ? "GalaxyImg3 animated fadeInRight" : "GalaxyImg3 animated fadeOutRight") : "GalaxyImg3"} alt="" />
            <div className="details">
                {!isDesktopOrLaptop && <Button className='learn-more' onClick={() => document.location.href='https://www.galaxy-balls.com/en'}>
                    LEARN MORE
                </Button>}
                <img src={AndroidBtnImg} className="AndroidBtnImg" alt="" />
                {isDesktopOrLaptop && <Button className='learn-more' onClick={() => document.location.href='https://www.galaxy-balls.com/en'}>
                    LEARN MORE
                </Button>}
                <img src={IOSBtnImg} className="IOSBtnImg" alt="" />
            </div>
        </div>
    )
}

export default GalxyBalls;
