import { useState } from 'react'
import Carousel from 'react-spring-3d-carousel'
import { useMediaQuery } from "react-responsive"
import variables from "../../../variables"
import Slider1 from '../../../Assets/Images/slider1.jpg'
import Slider2 from '../../../Assets/Images/slider2.jpg'
import Slider3 from '../../../Assets/Images/slider3.jpg'
import './PlantSlider.sass'

const slides = [
    {
        key: 11111,
        content: <img src={Slider1} className="Slider" alt="1" />,
    },
    {
        key: 22222,
        content: <img src={Slider2} className="Slider" alt="2" />,
    },
    {
        key: 33333,
        content: <img src={Slider3} className="Slider" alt="3" />,
    },
];

const PlantSlider = (props) => {
    const [selectedSlide, setSelectedSlide] = useState(0);
    const isDesktopOrLaptop = useMediaQuery({
        query: `(min-width: ${variables.breakpoints.tablet})`,
    })
    return (
        <div className="plansec">
            <h2>PLAN OF THE APARTMENT</h2>
            <div className="imgSlider">
                {isDesktopOrLaptop ? (<Carousel
                    slides={slides}
                    goToSlide={selectedSlide}
                    animationConfig={{ tension: 120, friction: 14 }}
                    onSlideClick={(slideIndex) => setSelectedSlide(slideIndex)}
                    offsetRadius={2}
                    showNavigation={true}
                />) : (<>
                    <img src={Slider1} className="SliderImg" alt="" />
                    <img src={Slider3} className="SliderImg" alt="" />
                    <img src={Slider2} className="SliderImg" alt="" />
                </>)}
            </div>
        </div>
    )
}

export default PlantSlider;
