import styles from './styles.module.sass'
import Menu from '../../Components/Menu'
import { Button } from "antd"
import { Link } from 'react-router-dom'
import LogoImg from "../../Assets/Images/logo.png"
import RocketImg from '../../Assets/Images/rocket.png'
import GalPhone1 from '../../Assets/Images/phone1.png'
import GalPhone2 from '../../Assets/Images/phone2.png'
import GalText1 from '../../Assets/Images/galText1.png'
import GalText2 from '../../Assets/Images/galText2.png'

const GalaxyHeader = () => {
    return (
        <header className={styles.galaxyHeader}>
            <Link to={'/'}><img src={LogoImg} className={styles.logoImg} alt="" /></Link>
            <Menu page="galaxy"/>
            <h1>Details of the GalaxyBalls app</h1>
            <p>Enhance your gaming<br /> experience with GalaxyBalls <br /><br />
                GalaxyBalls is a mobile application where you can find 
                both physical and virtual crystal balls. 
                The 400 points you purchase in the form of vouchers can 
                be used within the app to buy physical or virtual items.</p>
            <div className={styles.imgWrap}>
                <img src={RocketImg} className={styles.RocketImg} alt="" />
                <img src={GalPhone1} className={styles.GalPhone1} alt="" />
                <img src={GalText1} className={styles.GalText1} alt="" />
                <img src={GalPhone2} className={styles.GalPhone2} alt="" />
                <img src={GalText2} className={styles.GalText2} alt="" />
            </div>
            <div className={styles.homeBtnWrap}>
                <Button className={styles.visitHomeBtn} onClick={() => document.location.href='https://www.galaxy-balls.com/en'}>
                    VISIT HOMEPAGE
                </Button>
            </div>
            <div className={styles.galaxyShop}>
                <h2>Le Galaxy Shop</h2>
                <p>Discover the Galaxy Shop, the store within the GalaxyBalls app. 
                    Your UniZ points not only allow you to acquire enhancements 
                    for your game, but also offer you real physical products!</p>
                <ul>
                    <li><strong>Virtual Objects:</strong> Use your UniZ points to choose from a 
                    variety of virtual products that will help you 
                    progress in the GalaxyBalls adventure.</li>
                    <li><strong>Physical Products:</strong> Use your UniZ points to select from 
                    a range of unique items that will be delivered to your doorstep.</li>
                </ul>
                <p>Galaxy Shop, making the GalaxyBalls experience even 
                    more exciting and rewarding. Start earning your UniZ points now!</p>
            </div>
        </header>
    )
}

export default GalaxyHeader;
