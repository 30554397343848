/* eslint-disable */
import { Switch, Route } from "react-router-dom"
import Landing from "./Page/Landing"
import Galaxy from "./Page/Galaxy"
import AboutUs from "./Page/AboutUs"
import Payment from "./Page/Payment"
import Conditions from "./Page/Conditions"
import AppHelper from "./AppHelper"
import Login from "./Page/Login"

import {loadStripe} from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'

const App = () => {
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  return (
    <Switch>
      <Route path="/home">
        <Landing />
      </Route>
      <Route path="/galaxy">
        <Galaxy />
      </Route>
      <Route path="/about">
        <AboutUs />
      </Route>
      <Route path="/pay">
        <Elements stripe={stripePromise}>
          <Payment />
        </Elements>
      </Route>
      <Route path="/conditions">
        <Conditions />
      </Route>
      <Route path="/login">
        <Login />
      </Route>
      <Route path={"/"}>
          <AppHelper />
      </Route>
    </Switch>
  );
};

export default App;
