import { useState } from 'react'
import './LandingHeader.sass'
import Menu from '../../Components/Menu'
import SearchBox from '../../Components/Search'
import TicketImg from "../../Assets/Images/ticket1.png"
import UnionImg from "../../Assets/Images/union.png"
import MobileUnionImg from "../../Assets/Images/mobile-coins.png"
import GalaxyImg from "../../Assets/Images/GalaxyBalls.png"
import LogoImg from "../../Assets/Images/logo.png"
import SoldTicket from "../../Components/SoldTicket"
import variables from "../../variables"
import { Link } from 'react-router-dom'
import { useMediaQuery } from "react-responsive"

const LandingHeder = () => {
    const [showSearch, setShowSearch] = useState(false);
    const isDesktopOrLaptop = useMediaQuery({
        query: `(min-width: ${variables.breakpoints.tablet})`,
    })
    return (
        <header className="header">
            <Link to={'/'}><img src={LogoImg} className="logoImg" alt="" /></Link>
            <Menu showSearch={showSearch} setShowSearch={setShowSearch} />
            <div className="header_main">
                <div className="header_title">
                    <h1>WIN YOUR HOME WITH FORMAPRO <br />&amp;</h1>
                    <img src={GalaxyImg} className="GalaxyImg" alt="" />
                    <p>
                        Buy now a voucher of 400 UniZ<br /> points for only 3€! In addition, we <br /> 
                        offer you a ticket for the tombola. <br />
                        Don't miss this exceptional <br />
                        opportunity to win the <br />  apartment of your dreams!
                    </p>
                </div>
                <SearchBox showSearch={showSearch}/>
            </div>
            <div className="header_bottom">
                <img src={TicketImg} alt="" className="TicketImg animated zoomInLeft"/>
                <SoldTicket />
                {isDesktopOrLaptop ? (<img src={UnionImg} alt="" className="UnionImg animated zoomInRight"/>) 
                    : (<img src={MobileUnionImg} alt="" className="UnionImg animated zoomInRight"/>)}
            </div>
        </header>
    )
}

export default LandingHeder;
