import esternImg from '../../../Assets/Images/estern_bg.png'
import { Button } from "antd"
import { useHistory } from 'react-router-dom'
import './TakeYourTicket.sass'

const TakeYourTicket = (props) => {
    const history = useHistory()
    return (<>
        <div className="takeYourTicket">
            <img src={esternImg} alt="" className="esternImg" />
            <Button className='takeBtn' onClick={() => history.push('/pay')}>
                Take your ticket
            </Button>
        </div>
    </>)
}

export default TakeYourTicket;
