import { api_url } from "../Constants"

class AuthService {
  async login(username, password) {
    const keyHash = process.env.REACT_APP_APP_SECRET_KEY;
    let returnVal = '';
    await fetch(api_url + "/login", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({hash: keyHash, username: username, password:password})
    }).then(function(response) { return response.json(); })
    .then(function(json) {
      returnVal = json.message;
      if( json.message === 'success' )
        localStorage.setItem("ticketUser", JSON.stringify({username:username}));
    });
    return returnVal;
  }
  logout() {
    localStorage.removeItem("ticketUser");
  }

  register(username, email, password) {
    // return axios.post(API_URL + "signup", {
    //   username,
    //   email,
    //   password
    // });
  }

  getCurrentUser() {
    const userStr = localStorage.getItem("ticketUser");
    if (userStr) return JSON.parse(userStr);

    return null;
  }
}

const authObj = new AuthService();

export default authObj;
